/* eslint-disable react/jsx-pascal-case */
import { Button, Select } from "antd";
import { Infrastructures } from "shared/Infrastructure";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { useInfrastructure } from "./infrastructure.state";

const InfrastructureSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedInfrastructureId, setSelectedInfrastructureId } = useInfrastructure();

  return (
    <>
      <div>
        Infrastructure:
        <Select
          style={{ width: 270, marginLeft: 10 }}
          onChange={(infrastructureId) => {
            setSelectedInfrastructureId(infrastructureId);
          }}
          value={Infrastructures.filter((infrastructure) => infrastructure.id === selectedInfrastructureId)?.[0]?.id}
        >
          {Infrastructures
            //.filter((infrastructure) => !(infrastructure.id.includes("future") && location.pathname.includes("scenario-day")))
            .map(({ label, id }) => {
              return (
                <Select.Option key={label} value={id}>
                  {label}
                </Select.Option>
              );
            })}
        </Select>
        <Button type="link" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faMap} />
        </Button>
      </div>
    </>
  );
};

export default InfrastructureSelector;
