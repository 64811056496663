import React from "react";

const TstWarning = () => {
  const prodURL = "capacity-simulation.bac.jetpack.ai";
  const isLocalhost = window.location.host.includes("localhost");
  return /* process.env.NODE_ENV !== "production" && */ window.location.host !== prodURL && !isLocalhost ? (
    <div style={{ display: "block", backgroundColor: "red", color: "white", textAlign: "center" }}>
      WARNING ! This environnement is a prototype dedicated to developement. Production environnement is{" "}
      <a style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }} href={`https://${prodURL}`}>
        {prodURL}
      </a>
    </div>
  ) : null;
};

export default TstWarning;
