import { Infrastructures, InfrastructureId } from "shared/Infrastructure";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type InfrastructureSelectorState = {
  selectedInfrastructureId: InfrastructureId;
  setSelectedInfrastructureId: (selectedInfrastructureId: InfrastructureId) => void;
};
export const useInfrastructure = create(
  persist<InfrastructureSelectorState>(
    (set, get) => ({
      selectedInfrastructureId: Infrastructures[0].id,
      setSelectedInfrastructureId: (selectedInfrastructureId) => set({ selectedInfrastructureId }),
    }),
    {
      name: "infrastructure",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
