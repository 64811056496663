import { Menu } from "antd";
import { Link, useMatches } from "react-router-dom";
import { navigationMenuRoutes } from "../../routes";
import React from "react";

type NavigationMenuProps = {
  collapsed: boolean;
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({ collapsed }) => {
  const matches = useMatches();
  const currentPath = (matches?.filter((m) => m.handle).pop()?.pathname || "/").split("?")[0];
  const items = navigationMenuRoutes.map((route) => ({
    disabled: route.disabled || false,
    key: route.path || "/",
    icon: React.createElement(route.icon, {}),
    label: (
      <Link to={route.path || "#"}>
        <span>{route.title}</span>
      </Link>
    ),
    children: route.menu?.map((subRoute) => ({
      ...subRoute,
      label: (
        <Link to={subRoute.path || "#"}>
          <span>{subRoute.label}</span>
        </Link>
      ),
    })),
  }));

  return (
    <Menu
      items={items}
      mode="inline"
      theme="dark"
      selectedKeys={[currentPath]}
      style={{ background: "transparent" }} // right border fix
    />
  );
};

export default NavigationMenu;
