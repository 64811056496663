import { FastForwardOutlined, ForkOutlined, HistoryOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";
import React, { lazy, useEffect } from "react";
import { DataRouteObject, useRouteError } from "react-router";
import AppLayout from "./AppLayout";
import { QueryParamProvider } from "use-query-params";
import HomeButton from "./components/UI/HomeButton";
import RetryButton from "./components/UI/RetryButton";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import LogRocket from "logrocket";

const LoadingSpin = () => <Spin spinning={true} style={{ textAlign: "center", width: "100%", marginTop: "100px" }} />;

export const NotFound = () => <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." extra={<HomeButton />} />;
export const ErrorBoundary = () => {
  const error: unknown = useRouteError();
  useEffect(() => {
    if (error && error instanceof Error) {
      LogRocket.captureException(error, {
        tags: {
          CRASH: true,
        },
      });
    }
  }, [error]);
  return <Result status="500" title={error?.toString()} subTitle="Sorry, something went wrong" extra={<RetryButton />} />;
};

export const routes: DataRouteObject[] = [
  {
    id: "root",
    path: "/",
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AppLayout />
      </QueryParamProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        id: "home",
        path: "/",
        index: true,
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Home/Home")),
              {},
            )}
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        handle: {
          title: "Home",
        },
      },

      {
        id: "historical-what-if-simulations",
        path: "/historical-what-if-simulations",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/HistoricalWhatIfSimulation/Explorer/HistoricalWhatIfSimulationExplorer")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Historical What-if Simulations",
          icon: HistoryOutlined,
        },
        children: [
          {
            id: "historical-what-if-simulation/new",
            path: "/historical-what-if-simulations/new",
            element: (
              <React.Suspense fallback={<LoadingSpin />}>
                {React.createElement(
                  lazy(() =>
                    import("./components/HistoricalWhatIfSimulation/Explorer/HistoricalWhatIfSimulationForm").then((module) => ({
                      default: module.NewHistoricalWhatIfSimulationPage,
                    })),
                  ),
                  {},
                )}
              </React.Suspense>
            ),
          },
          {
            id: "historical-what-if-simulation/id",
            path: "/historical-what-if-simulations/:id",
            children: [
              {
                id: "historical-what-if-simulation/id/",
                path: "/historical-what-if-simulations/:id",
                element: (
                  <React.Suspense fallback={<LoadingSpin />}>
                    {React.createElement(
                      lazy(() =>
                        import("./components/HistoricalWhatIfSimulation/Explorer/HistoricalWhatIfSimulationView").then((module) => ({
                          default: module.HistoricalWhatIfSimulationViewPage,
                        })),
                      ),
                      {},
                    )}
                  </React.Suspense>
                ),
              },
              {
                id: "historical-what-if-simulation/id/edit",
                path: "/historical-what-if-simulations/:id/edit",
                element: (
                  <React.Suspense fallback={<LoadingSpin />}>
                    {React.createElement(
                      lazy(() =>
                        import("./components/HistoricalWhatIfSimulation/Explorer/HistoricalWhatIfSimulationForm").then((module) => ({
                          default: module.EditHistoricalWhatIfSimulationPage,
                        })),
                      ),
                      {},
                    )}
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        id: "future-what-if-simulations",
        path: "/future-what-if-simulations",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/FutureWhatIfSimulation/Explorer/FutureWhatIfSimulationExplorer")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Future What-if Simulations",
          icon: FastForwardOutlined,
        },
        children: [
          {
            id: "future-what-if-simulation/new",
            path: "/future-what-if-simulations/new",
            element: (
              <React.Suspense fallback={<LoadingSpin />}>
                {React.createElement(
                  lazy(() =>
                    import("./components/FutureWhatIfSimulation/Explorer/FutureWhatIfSimulationForm/FutureWhatIfSimulationForm").then((module) => ({
                      default: module.NewFutureWhatIfSimulationPage,
                    })),
                  ),
                  {},
                )}
              </React.Suspense>
            ),
          },
          {
            id: "future-what-if-simulation/id",
            path: "/future-what-if-simulations/:id",
            children: [
              {
                id: "future-what-if-simulation/id/",
                path: "/future-what-if-simulations/:id",
                element: (
                  <React.Suspense fallback={<LoadingSpin />}>
                    {React.createElement(
                      lazy(() =>
                        import("./components/FutureWhatIfSimulation/Explorer/FutureWhatIfSimulationView").then((module) => ({
                          default: module.FutureWhatIfSimulationViewPage,
                        })),
                      ),
                      {},
                    )}
                  </React.Suspense>
                ),
              },
              {
                id: "future-what-if-simulation/id/edit",
                path: "/future-what-if-simulations/:id/edit",
                element: (
                  <React.Suspense fallback={<LoadingSpin />}>
                    {React.createElement(
                      lazy(() =>
                        import("./components/FutureWhatIfSimulation/Explorer/FutureWhatIfSimulationForm/FutureWhatIfSimulationForm").then(
                          (module) => ({
                            default: module.EditFutureWhatIfSimulationPage,
                          }),
                        ),
                      ),
                      {},
                    )}
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        id: "comparator",
        path: "/comparator",
        handle: {
          title: "Simulations Comparator",
          icon: ForkOutlined,
          menu: [
            {
              id: "comparator/historical-what-if-simulations",
              icon: <HistoryOutlined />,
              path: "/comparator/historical-what-if-simulations",
              label: "Historical What-if Simulations",
            },
            {
              id: "comparator/future-what-if-simulations",
              icon: <FastForwardOutlined />,
              path: "/comparator/future-what-if-simulations",
              label: "Future What-if Simulations",
            },
          ],
        },
        children: [
          {
            id: "comparator/historical-what-if-simulations/layout",
            path: "/comparator/historical-what-if-simulations",
            handle: {
              title: "Historical What-if Simulations Comparator",
            },
            element: (
              <React.Suspense fallback={<LoadingSpin />}>
                {React.createElement(
                  lazy(() => import("./components/HistoricalWhatIfSimulation/Comparator/HistoricComparatorLayout")),
                  {},
                )}
              </React.Suspense>
            ),
            children: [
              {
                id: "comparator/historical-what-if-simulations",
                path: "/comparator/historical-what-if-simulations",
                element: (
                  <React.Suspense fallback={<LoadingSpin />}>
                    {React.createElement(
                      lazy(() => import("./components/HistoricalWhatIfSimulation/Comparator/HistoricComparatorView")),
                      {},
                    )}
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            id: "comparator/future-what-if-simulations/layout",
            path: "/comparator/future-what-if-simulations",
            handle: {
              title: "Future What-if Simulations Comparator",
            },
            element: (
              <React.Suspense fallback={<LoadingSpin />}>
                {React.createElement(
                  lazy(() => import("./components/FutureWhatIfSimulation/Comparator/FutureComparatorLayout")),
                  {},
                )}
              </React.Suspense>
            ),
            children: [
              {
                id: "comparator/future-what-if-simulations",
                path: "/comparator/future-what-if-simulations",
                element: (
                  <React.Suspense fallback={<LoadingSpin />}>
                    {React.createElement(
                      lazy(() => import("./components/FutureWhatIfSimulation/Comparator/FutureComparatorView")),
                      {},
                    )}
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        id: "not-found",
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

const appendChildren = <R extends typeof routes>(routes: R): DataRouteObject[] =>
  routes.map((route) => (route?.children ? [route, ...appendChildren(route.children)] : route)).flat();

export const navigationMenuRoutes = appendChildren(routes)
  .filter((route) => !!route.handle?.icon)
  .map((route) => ({
    id: route.id,
    path: route.path,
    title: route.handle?.title,
    icon: route.handle?.icon,
    disabled: route.handle?.disabled,
    menu: route.handle?.menu,
  }));
