"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.infrastructureFactory = exports.findInfrastructureById = exports.Infrastructures = void 0;
exports.Infrastructures = [
    {
        id: "border-connector",
        label: "Border Connector",
        airport_component: "ScreeningBorderConnector",
        nbBooths: 8,
        nbeGates_eu: 1,
        nbeGates_tcn: 1,
        s3Folder: "border-connector",
        dbValue: "BORDER_CONNECTOR",
        queues: ["ABC", "EU", "TCN"],
    },
    {
        id: "border-arrival",
        label: "Border Arrival",
        airport_component: "BorderArrival",
        nbBooths: 10,
        nbeGates_eu: 1,
        nbeGates_tcn: 0,
        s3Folder: "border-arrival",
        dbValue: "BORDER_ARRIVAL",
        queues: ["ABC", "EU", "TCN"],
    },
    {
        id: "screening-connector",
        label: "Screening Connector",
        airport_component: "Screening",
        nbBooths: 0,
        nbeGates_eu: 0,
        nbeGates_tcn: 0,
        s3Folder: "screening-connector",
        dbValue: "SCREENING_CONNECTOR",
        queues: ["Fastlane", "Screening-1", "Screening-2", "Screening-3"],
    },
];
function findInfrastructureById(id) {
    return exports.Infrastructures.find((infrastructure) => infrastructure.id === id);
}
exports.findInfrastructureById = findInfrastructureById;
const infrastructureFactory = (mapping) => (id) => {
    return mapping[id];
};
exports.infrastructureFactory = infrastructureFactory;
